<template>
  <div>
    <h1>Skapa presentation</h1>
    <div class="slides__container">
      <div>
        <h2>Slides</h2>
        <div v-for="(slide, index) in slides" :key="slide.id" @click="selectMainSlide(index)">{{ slide.title ? slide.title : 'Tom slide' }}</div>
        <div class="btn" @click="newSlide">Lägg till slide</div>
      </div>
      <div>
        <h2>Slide-info</h2>
        <input type="text" v-model="slides[selectedSlide].title" placeholder="Titel">
        <input type="text" v-model="slides[selectedSlide].description" placeholder="Beskrivning">
        <input type="text" v-model="slides[selectedSlide].imageUrl" placeholder="Bild">
        <input type="number" v-model="slides[selectedSlide].order" placeholder="Ordning">
        <!-- <div class="btn" @click="saveSlide">Spara</div> -->
        <h3>Underslides</h3>
        <ul v-if="slides[selectedSlide].subslides.length">
          <li v-for="(subslide, index) in slides[selectedSlide].subslides" :key="subslide.id" @click="selectedSubSlide = index">{{ index + 1 }}</li>
        </ul>
        <div class="btn" @click="newSubSlide">Lägg till underslide</div>
      </div>
      <div>
        <h2>Subslide</h2>
        <div v-if="slides[selectedSlide].subslides.length">
          <input type="text" v-model="slides[selectedSlide].subslides[selectedSubSlide].imageUrl" placeholder="Bildlänk">
          <input type="range" v-model.number="slides[selectedSlide].subslides[selectedSubSlide].x" min="0" max="100">
          <input type="range" v-model.number="slides[selectedSlide].subslides[selectedSubSlide].y" min="0" max="100">
        </div>
      </div>
    </div>
    <div class="btn" @click="saveToFirebase">Save presentation</div>
  </div>
</template>

<script>
import * as fb from '../firebase'

export default {
  data: function () {
    return {
      title: "",
      slides: [{
        title: "",
        description: "",
        imageUrl: "",
        order: "",
        subslides: []
      }],
      presenter: [],
      selectedSlide: 0,
      selectedSubSlide: 0,
    };
  },
  methods: {
    newSlide() {
      this.selectedSlide = this.slides.length
      this.slides.push({title: "", description: "", imageUrl: "", order: "", subslides: []})
    },
    newSubSlide() {
      this.selectedSubSlide = this.slides[this.selectedSlide].subslides.length
      this.slides[this.selectedSlide].subslides.push({imageurl: "", x: 0, y: 0, open:false, title:""})
    },
    selectMainSlide(index) {
      this.selectedSlide = index
      this.selectedSubSlide = 0
    },
    saveToFirebase(){
      fb.db.collection('presentations').add({
        title: "test",
        presentationStarted: false,
        browsingActive: false,
        activeSlide: 0,
        slides: this.slides
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    }
  }
};
</script>

<style lang="scss">
.slides__container {
  display: flex;
  flex-direction: row;
  div {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

</style>